import React, { useState, useCallback, useEffect } from "react"
import {
  Page,
  Select,
  FormLayout,
  Badge,
  LegacyStack,
  Button,
  Modal,
  Banner,
  LegacyCard,
  Box,
  Text,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  TextContainer,
} from "@shopify/polaris"
import { withFirebase } from "../../../providers/firebase"
import CustomDesign from "../customdesign/CustomDesign"
import getCheckoutProfiles from "../../../helpers/getCheckoutProfiles"
import updateCheckoutBranding from "../../../helpers/updateCheckoutBranding"
import { navigate } from "gatsby"
function Design(props) {
  const { token, shop, host, location } = props
  const backLink = location && location.state && location.state.backLink
  const [profilesData, setProfilesData] = useState(null)
  const [selected, setSelected] = useState(null)
  const [brandingData, setBrandingData] = useState(null)
  const [active, setActive] = useState(false)
  const [defaultData, setDefaultData] = useState(0)
  const [loading, setLoading] = useState(false)
  const [bannerActive, setBannerActive] = useState(true)
  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      let checkoutProfiles
      try {
        checkoutProfiles = await getCheckoutProfiles(token, shop, host)
        console.log("checkoutProfiles", checkoutProfiles)
      } catch (e) {
        console.log(e)
      }
      if (checkoutProfiles && checkoutProfiles.data) {
        setProfilesData(checkoutProfiles.data)
      }
      setLoading(false)
    }
    fetchData()
  }, [])
  
  const handleSelectChange = useCallback((value) => setSelected(value), [])
  const handleChange = useCallback(() => setActive(!active), [active])

  const activator = (
    <Button   onClick={handleChange}>
      Reset to default
    </Button>
  )
  const resetToDefault = async () => {
    setLoading(true)
    const updateBrandingData = {
      designSystem: null,
      customizations: null,
    }
    
    const brandingResponse = await updateCheckoutBranding(
      {
        checkoutProfileId: selected ? selected : profilesData?.checkoutProfiles?.edges[0].node.id,
        checkoutBrandingInput: updateBrandingData,
        resetToDefault: true,
      },
      token,
      shop,
      host
    )
    setBrandingData(brandingResponse.data)
    setDefaultData(defaultData+1)
    setLoading(false)
    handleChange()
  }
  
  const options = profilesData?.checkoutProfiles?.edges
    ? profilesData.checkoutProfiles.edges
        .sort((a, b) => {
          return a.node.isPublished && !b.node.isPublished ? -1 : 1
        })
        .map((profile) => {
          return {
            label: profile.node.name,
            value: profile.node.id,
          };
        })
    : []
  return (
    <Page backAction={{
                content: 'Settings',
                onAction: () => {
                    if("settings"===backLink){
                      navigate("/app/settings")
                    }else{
                      navigate("/app/customizations/createCustomizations")
                    }
                }}} 
                title="Checkout branding"
                secondaryActions={
                  <Modal
              activator={activator}
              open={active}
              onClose={handleChange}
              title="Reset to default styles?              "
              primaryAction={{
                content: "Yes, reset",
                onAction: resetToDefault,
                loading: loading,
              }}
              secondaryActions={[
                {
                  content: "Cancel",
                  onAction: handleChange,
                },
              ]}
            >
              <Modal.Section>
                <p>
                  Are you sure you want to reset Default Profile styles to
                  default values?
                </p>
              </Modal.Section>
            </Modal>
                }
                >
            {loading ? 
            <Layout>
            <Layout.Section>
            <LegacyCard sectioned>
              <SkeletonBodyText />
            </LegacyCard>
            <LegacyCard sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </LegacyCard>
          </Layout.Section>
          </Layout>
            :
            <Layout>
              <Layout.Section>
            {(selected === null ||
              (selected &&
                profilesData?.checkoutProfiles?.edges.find(
                  (profile) => profile.node.id === selected
                ).node.isPublished)) && bannerActive && (
             
              <Banner onDismiss={() => {setBannerActive(false)}}>
                <p>
                You are editing the live checkout. Switch profiles if you want to edit a draft.
                </p>
              </Banner>
            )}
          <Box paddingBlock="400">
            <LegacyCard title="Profile">
              <Box padding="400">
              <LegacyStack vertical spacing="baseTight">
              {    
                (selected &&
                  (<LegacyStack><Text as="p" tone="subdued">{profilesData?.checkoutProfiles?.edges.find(
                    (profile) => profile.node.id === selected
                  ).node.name}</Text>
                  {profilesData?.checkoutProfiles?.edges.find(
                    (profile) => profile.node.id === selected
                  ).node.isPublished ? <Badge tone="success">Live</Badge>: null}</LegacyStack> )) 
                  || 
                  (selected === null && (<LegacyStack><Text as="p" tone="subdued">{profilesData?.checkoutProfiles?.edges[0]?.node.name} </Text><Badge tone="success">Live</Badge></LegacyStack>)) 
                  
                }
                <Select
                  label="Choose the checkout profile to customize"
                  options={options}
                  onChange={handleSelectChange}
                  value={selected}
                />
                </LegacyStack>
                </Box>
            </LegacyCard>
          </Box>
        <FormLayout>
        {profilesData?.checkoutProfiles?.edges ? (
          <CustomDesign
            checkoutProfileId={
              selected
                ? selected
                : profilesData?.checkoutProfiles?.edges[0].node.id
            }
            liveProfile={
              selected === null ||
              (selected &&
                profilesData?.checkoutProfiles?.edges.find(
                  (profile) => profile.node.id === selected
                ).node.isPublished)
            }
            brandingData={brandingData}
            setBrandingData={setBrandingData}
            defaultData={defaultData}
            token={token}
            shop={shop}
            host={host}
            location={location}
            key={selected}
          />
        ) : null}
      </FormLayout>
      </Layout.Section>
      </Layout>
      }
    </Page>
  );
}

export default withFirebase(Design)
